<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="analyticplanData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="8">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Plan Analytique :: <strong> {{ analyticplanData.code }} </strong>
            </h6>
          </b-col>

          <b-col cols="4">
            <b-button
              v-if="$router.name == 'analyticplan-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'analyticplan-view',
                  params: { id: analyticplanData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'analyticplan-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'analyticplan-view',
                  params: { id: analyticplanData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'analyticplan-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Lister Plans Analytiques</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="" v-for="(outcome, outcome_index) in analyticplanData.outcome.data"> 

          <!-- numero -->
          <b-col cols="12" md="2"> 
              <b-form-group>
                <label for="account" v-if="outcome_index==0">N° de Section / Compte</label>
                <b-form-input
                  id="account"
                  v-model="outcome.account" 
                  trim
                  placeholder="Ex: 356"
                  class=""
                  size="sm"
                /> 
              </b-form-group> 
          </b-col>             

          <!-- name -->
          <b-col cols="12" md="7"> 
              <b-form-group>
              <label for="name" v-if="outcome_index==0">Intitulé de la Section</label>
                <b-form-input
                  id="name"
                  v-model="outcome.name"
                  trim
                  placeholder="Ex: Achat de Terrain"
                  class=""
                  size="sm"
                /> 
              </b-form-group> 
          </b-col>   

          <b-col cols="6" md="2">
            <!-- type -->
            <validation-provider
              #default="validationContext"
              name="type"
              rules="required"
            >
              <b-form-group
                :state="getValidationState(validationContext)"
              >
                <label for="type" v-if="outcome_index==0">Type</label>
                <v-select
                  v-model="analyticplanData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="analyticSectionTypeOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="type"
                  size="sm"
                  class="select-size-sm"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  Sélectionner Type svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>      

          <!-- options -->
          <b-col cols="12" md="1"> 
              <b-form-group>
                <label for="options" v-if="outcome_index==0">Options</label>
                
                <b-button
                  variant="outline-primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1 cursor-pointer"
                  size="sm"
                  id="options"
                  :id="`form-item-settings-icon-${outcome_index}`" 
                          icon="SettingsIcon" 
                >
                  <feather-icon icon="SettingsIcon" size="12" /> 
                </b-button>

                <!-- <feather-icon
                          :id="`form-item-settings-icon-${outcome_index}`"
                          size="16"
                          icon="SettingsIcon"
                          class="cursor-pointer"
                        /> -->

                        <!-- Setting Item Form -->
                        <b-popover
                          :ref="`form-item-settings-popover-${outcome_index}`"
                          :target="`form-item-settings-icon-${outcome_index}`"
                          triggers="focus"
                          placement="left"
                        >
                          <b-form @submit.prevent>
                            <b-row>
                              <!-- Field: Discount -->
                              <b-col cols="12">
                                <b-form-group
                                  label="Discount(%)"
                                  :label-for="`setting-item-${outcome_index}-discount`"
                                >
                                  <b-form-input
                                    :id="`setting-item-${outcome_index}-discount`"
                                    :value="null"
                                    type="number"
                                  />
                                </b-form-group>
                              </b-col>

                              <!-- Field: Tax 1 -->
                              <b-col cols="6">
                                <b-form-group
                                  label="Tax 1"
                                  :label-for="`setting-item-${outcome_index}-tax-1`"
                                >
                                  <v-select
                                    :dir="
                                      $store.state.appConfig.isRTL
                                        ? 'rtl'
                                        : 'ltr'
                                    "
                                    :value="'10%'"
                                    :options="['0%', '1%', '10%', '14%', '18%']"
                                    :input-id="`setting-item-${outcome_index}-tax-1`"
                                    :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>

                              <!-- Field: Tax 2 -->
                              <b-col cols="6">
                                <b-form-group
                                  label="Tax 2"
                                  :label-for="`setting-item-${outcome_index}-tax-2`"
                                >
                                  <v-select
                                    :dir="
                                      $store.state.appConfig.isRTL
                                        ? 'rtl'
                                        : 'ltr'
                                    "
                                    :value="'10%'"
                                    :options="['0%', '1%', '10%', '14%', '18%']"
                                    :input-id="`setting-item-${outcome_index}-tax-2`"
                                    :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>

                              <b-col
                                cols="12"
                                class="d-flex justify-content-between mt-1"
                              >
                                <b-button
                                  variant="outline-primary"
                                  @click="
                                    () => {
                                      $refs[
                                        `form-item-settings-popover-${outcome_index}`
                                      ][0].$emit('close');
                                    }
                                  "
                                >
                                  Apply
                                </b-button>
                                <b-button
                                  variant="outline-secondary"
                                  @click="
                                    () => {
                                      $refs[
                                        `form-item-settings-popover-${outcome_index}`
                                      ][0].$emit('close');
                                    }
                                  "
                                >
                                  Cancel
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </b-popover>
                        

              </b-form-group> 
          </b-col>
           
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">  
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>

            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="analyticplanData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// analyticplans
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BMedia,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BAvatar,
  BFormFile,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
  BMediaBody,
  BImg,
} from "bootstrap-vue";

// alerts

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "analyticplan";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormDatepicker,
    Logo,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BImg,
    BAvatar,
    BTable,
    BCardHeader,
    BCardTitle, 

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      bankOptions: [],  
    };
  },
  props: {
    analyticplanData: {
      type: Object,
    },
    defaultanalyticplanData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {

    console.log('this.analyticplanData.outcome');
    console.log(this.analyticplanData);

    if (router.currentRoute.name == moduleName + "-add-new") {
      var now = new Date();
      this.sku_analyticplan = "ag-" + now.getTime();

      this.operationTitle = "Nouveau ";
      this.imageActionText = "Ajouter ";
      this.operationIcon = "AddIcon";

      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.sku_analyticplan = router.currentRoute.params.id;
      this.operationTitle = "Détails du '";
      this.imageActionText = "?";
      this.operationIcon = "EyeIcon";

      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }

    if (router.currentRoute.name == moduleName + "-edit") {
      this.sku_analyticplan = router.currentRoute.params.id;
      this.operationTitle = "Structure du ";
      this.operationIcon = "EditIcon";
      this.imageActionText = "Modifier";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier";
      this.actionIndex = 3;
      this.showed = true;
    }

    // get agents
    await myApi
      .get(`bankaccount`)
      .then((response) => {
        // default action
        var data = response.data.data;
        console.log(data);

        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.bankOptions.push({
            label:
              data[i].name +
              " - " +
              data[i].number +
              " | " +
              data[i].currency,
            value: data[i].name,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    calculateRemainingaccountingFileSumDays() {
      // ------------------------------
      // ------------------------------
    },
    reinitForm() {
      this.$props.analyticplanData = this.defaultanalyticplanData;

      if (router.currentRoute.name == "analyticplan-add-new") {
        // init empty analyticplan data
        // analyticplanData = this.defaultanalyticplanData;
      } else {
        // requirement //
        // store
        //   .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
        //     id: router.currentRoute.params.id,
        //   })
        //   .then((response) => {
        //     analyticplanData.value = response.data;
        //   })
        //   .catch((error) => {
        //     if (error.response.status === 404) {
        //       analyticplanData.value = undefined;
        //     }
        //   });
      }
      // remove all occurences
      // initialise 1 element
      // this.$props.analyticplanData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    async addUpdateItemAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "analyticplan-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "analyticplan-view") {
        method = "get";
        params = "/" + this.analyticplanData.id;
      }
      if (router.currentRoute.name == "analyticplan-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.analyticplanData.id;
      }

      // finalise form data
      formData = this.$props.analyticplanData;

      await myApi({
        url: "analyticplan" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "analyticplan-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "analyticplan-view" });
    },
    goToNextItem() {
      router.push({ name: "analyticplan-list" });
    },
    goToList() {
      router.push({ name: "analyticplan-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // analyticplan
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-analyticplan";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetanalyticplanData = () => {
      // this.analyticplanData = this.$props.analyticplanData;
    };

    let { refFormObserver, getValidationState, resetForm } = formValidation(
      resetanalyticplanData
    );

     const analyticSectionTypeOptions = [
      { label: "Type Détails", value: "Détails" },
      { label: "Type Total", value: "Total" },
    ];

    return {
      refFormObserver,
      getValidationState,
      analyticSectionTypeOptions,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .banalyticplan {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>